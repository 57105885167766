import { PaymentMethodEnum } from '../../constants/enum/PaymentMethod';
import Api from '../index';
import {
  GetTokenRequestDTO,
  GetMiniAppTokenResponseDTO,
} from './dto/getMiniAppToken.dto';

export enum PoolStatus {
  OPEN = 1,
  CLOSE = 2,
}

export interface IToken {
  pageIndex?: number;
  pageSize?: number;
  tokenAddress?: string;
  symbol?: string;
  name?: string;
  search?: string;
  img?: string;
}

export interface IRegister {
  emailAddress: string;
  password: string;
  walletAddress?: string;
  signature?: string;
  message?: string;
  affiliateCode?: string;
  notificationType?: string[];
  telegramId?: number;
  chatId?: number;
}

export interface IBlockParam {
  gameId: string;
  walletAddress: string;
  level: string;
}

export interface ILogin {
  emailAddress: string;
  password: string;
  telegramId?: number;
  chatId?: number;
}

export interface ILoginOAuth {
  code: string;
  state: string;
  telegramId?: number;
  chatId?: number;
}

export interface ILoginByWallet {
  walletAddress: string;
  signature: string;
  message: string;
  telegramId?: number;
  chatId?: number;
}

export interface IGameActiveQuery {
  pageSize?: number;
  pageIndex?: number;
  scoringModelType?: string;
  showStatistic?: boolean;
  notShowTestGames?: boolean;
}

export interface IGameScoreQuery {
  pageSize?: number;
  pageIndex?: number;
  gameId?: string;
  level?: number;
  sortField?: string;
  sortDirection?: string;
}

export interface IGameLevelQuery {
  pageSize?: number;
  pageIndex?: number;
  gameId?: string;
  sortField?: string;
  sortDirection?: string;
}

export interface ICheckUsernameExists {
  isExisting: boolean;
  player?: {
    username: string;
    avatarURL?: string;
  };
}

export interface IConvertToken {
  supportedTokenAddress: string;
  paymentTokenAddress: string;
  amount: string;
}

export interface IGameRevenueList {
  pageSize?: number;
  pageIndex?: number;
  isCurrentMaster?: boolean;
}

export interface IGameVersion {
  gameId: string;
  version: string;
}

export interface RecaptchaResponse {
  statusCode: number;
  success: string;
  message: string;
}

// Player
export interface IDesktopSubscription {
  subscriptionId: string;
}

export interface ITimeRange {
  startDate: number;
  endDate: number;
}

export interface ITime {
  timeType: string;
}

export interface IPaymentCheckout {
  amount: number;
  quantity: number;
}

export interface IMailResetPassword {
  emailAddress: string;
}

export interface IResetPassword {
  password: string;
  token: string;
}

// Player

export interface IResetPassword {
  password: string;
  token: string;
}

export interface IDownloadGamerData {
  gameId: string;
  startDate: number;
  endDate: number;
  username: string;
  includeScore: boolean;
}

export const ping = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/ping',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// Player
export const getPlayer = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/player',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const login = async (data: ILogin) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/login',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const loginOAuth = async (data: ILoginOAuth) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/oauth/CARV',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const listOwnedLevels = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/player/owned-level',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const postRegister = async (data: IRegister) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/register',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const sendMailResetPassword = async (data: IMailResetPassword) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/send-mail-reset-password',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const resetPassword = async (data: IResetPassword) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/reset-password',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getAttempts = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/token-balance/remaining-list',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getToken = async (data: IToken) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const postTokenWithAddress = async (data: IToken) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/token/create-with-address',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const generateSignatureStartLevel = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game-session/start-level',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const checkFinishLevel = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game-session/check-can-finish',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const generateSignatureFinishLevel = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game-session/finish-level',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const latestBlock = async (data: IBlockParam) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/game-session/latest',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getClientId = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: {},
      },
      endpoint: '/mock-ws-client/connect',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getOwnerLevels = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/leaderboard/owner-levels',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getTokenEarned = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/leaderboard/token-earned',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getGameStatistic = async (gameId: string, level?: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: {},
      },
      endpoint: level
        ? `/leaderboard/game-statistic/${gameId}/${level}`
        : `/leaderboard/game-statistic/${gameId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getListLevel = async (gameId: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: {},
      },
      endpoint: `/leaderboard/game-level-data/${gameId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getGameScore = async (data: IGameScoreQuery) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/leaderboard/game-score-leaderboard',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getGameLevel = async (data: IGameLevelQuery) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/leaderboard/game-level-leaderboard',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getDetailPoster = async (id: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: {},
      },
      endpoint: `/game-poster/detail/${id}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const totalPlayersAndAttempts = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/dashboard/total-players-and-attempts',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const totalValue = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/dashboard/total-revenue',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const totalTokenIncome = async (gameId: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: { gameId },
      },
      endpoint: '/dashboard/total-token-income',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getGameList = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/game',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const searchGameList = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/game/search',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const predefined = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/predefined-game',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const generateSignatureAddGame = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game/signature',
    });
    return res?.payload?.data;
  } catch (e) {
    console.log(e.message);
  }
};

export const getGameDetail = async (gameId: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: { params: {} },
      endpoint: `/game/${gameId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const updateGameDetail = async (gameId: string, data) => {
  try {
    const res = await Api.request({
      method: 'put',
      data,
      endpoint: `/game/${gameId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getGameActiveDetail = async (gameId: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: { params: {} },
      endpoint: `/game/active/${gameId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getGameActive = async (data: IGameActiveQuery) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/game/active',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getSupportedTokenList = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/supported-token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getLevelFee = async (gameId: string, level: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: { gameId, level },
      },
      endpoint: `/level-fee`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getPlatformConfig = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/platform-configuration',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const setPlatformConfig = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/platform-configuration',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getProviderWhitelist = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/provider-whitelist',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const addProviderWhitelist = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/provider-whitelist',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const removeProviderWhitelist = async data => {
  try {
    const res = await Api.request({
      method: 'delete',
      data: {
        data,
      },
      endpoint: '/provider-whitelist',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getTesterList = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/tester',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const addTester = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/tester',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const removeTester = async data => {
  try {
    const res = await Api.request({
      method: 'delete',
      data: {
        data,
      },
      endpoint: '/tester',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getAffiliate = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/affiliate',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getAdmin = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const addAdmin = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/admin',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const removeAdmin = async data => {
  try {
    const res = await Api.request({
      method: 'delete',
      data: {
        data,
      },
      endpoint: '/admin',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getInfluencerWhitelist = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/influencer-whitelist',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const addInfluencerWhitelist = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/influencer-whitelist',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const removeInfluencerWhitelist = async data => {
  try {
    const res = await Api.request({
      method: 'delete',
      data: {
        data,
      },
      endpoint: '/influencer-whitelist',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const addSupportedToken = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/supported-token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const removeSupportedToken = async data => {
  try {
    const res = await Api.request({
      method: 'delete',
      data: {
        data,
      },
      endpoint: '/supported-token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const registerGame = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game/register',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const setGameActiveStatus = async (gameId: string, data) => {
  try {
    const res = await Api.request({
      method: 'put',
      data,
      endpoint: '/game/status/' + gameId,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const checkProviderWhitelist = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/provider-whitelist/check',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const checkGamePathAvailable = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/game/check-slug-available/' + data,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const generateSignatureToWithdraw = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/withdraw-request/get-signature-to-withdraw',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getListLevelFee = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/level-fee/list',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const setLevelFee = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/level-fee',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const checkConnectedWallet = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/player/check-wallet-connected',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const loginByWallet = async (data: ILoginByWallet) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/login-by-wallet',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const linkWallet = async (data: ILoginByWallet) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/connect-wallet',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const updateWallet = async (data: ILoginByWallet) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/update-wallet',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const verifyOtp = async (emailAddress: string, otp: string) => {
  try {
    const res = await Api.request({
      method: 'put',
      data: {
        emailAddress,
        otp,
      },
      endpoint: '/player/verify-otp',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const resendOtp = async (emailAddress: string) => {
  try {
    const res = await Api.request({
      method: 'put',
      data: { emailAddress },
      endpoint: '/player/resend-otp',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const setupProfile = async data => {
  try {
    const res = await Api.request({
      method: 'put',
      data,
      endpoint: '/player/profile',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const oneTimeToken = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game-play/one-time-token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const oneTimeTokenForMiniApp = async (
  data: GetTokenRequestDTO,
): Promise<GetMiniAppTokenResponseDTO> => {
  const res = await Api.request({
    method: 'post',
    data,
    endpoint: '/telegram/auth/mini-app/token',
  });
  if (!res.success) throw new Error(res.message);
  return res.payload.data as GetMiniAppTokenResponseDTO;
};

export const verifyOneTimeToken = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game-play/verify-one-time-token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getRemainingBalance = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/token-balance',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const refreshToken = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/player/refresh-token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const logout = async refreshToken => {
  try {
    const res = await Api.request({
      method: 'put',
      data: { refreshToken },
      endpoint: '/player/log-out',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// User profile page
export const deletePlayerAccount = async () => {
  try {
    const res = await Api.request({
      method: 'delete',
      data: {},
      endpoint: '/player',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const updatePlayerEmail = async data => {
  try {
    const res = await Api.request({
      method: 'put',
      data,
      endpoint: '/player/email',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const playerVerifyOtp = async (emailAddress: string, otp: string) => {
  try {
    const res = await Api.request({
      method: 'put',
      data: {
        emailAddress,
        otp,
      },
      endpoint: '/player/email/verify-otp',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getPlayerGameStatistics = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/player/game/statistics',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getPlayerGameRevenueTotal = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/player/game/revenue/total',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getPlayerGameLevelMaster = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/player/game/level-mastered',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const checkUsernameExists = async username => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/player/check-username-exists/' + username,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getPlayerGameRevenueList = async (data: IGameRevenueList) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/player/game/revenue/list',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getScoringModelList = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/game/scoring-models',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// Cashier page
export const getListPaymentToken = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/payment-token',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const convertPaymentToSupported = async (data: IConvertToken) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/convert/payment-to-supported',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const convertSupportedToPayment = async (data: IConvertToken) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/convert/supported-to-payment',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getSupportedToken = async address => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/supported-token/' + address,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getLowestFee = async (gameId: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: {
          gameId,
        },
      },
      endpoint: '/level-fee/lowest',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// Game-version
export const addNewGameVersion = async data => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: '/game-version',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const setActiveGameVersion = async (gameId: string, version: string) => {
  try {
    const res = await Api.request({
      method: 'put',
      data: {
        gameId,
        version,
      },
      endpoint: '/game-version/activate',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const removeOldGameVersion = async (gameId: string, version: string) => {
  try {
    const res = await Api.request({
      method: 'delete',
      data: {
        gameId,
        version,
      },
      endpoint: `/game-version/${gameId}/${version}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const verifyRecaptcha = async (recaptchaValue: string) => {
  try {
    const res = await Api.request({
      method: 'post',
      data: {
        recaptchaValue,
      },
      endpoint: `/recaptcha/verify`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const createDesktopSubscription = async (data: IDesktopSubscription) => {
  try {
    const res = await Api.request({
      method: 'post',
      data,
      endpoint: `/notification-setting/desktop/${data.subscriptionId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// admin-statistic
export const getTotalMetricsSummary = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/total-metrics/summary',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getTotalMetricsPlatformRevenue = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/total-metrics/total-platform-revenue',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getTotalMetricsScoringModelRevenue = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/total-metrics/total-scoring-model-revenue',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getAverageMetricsAverageUserSpent = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/average-metrics/average-user-spent',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getTopMetricsTopUsers = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/top-metrics/top-users',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getTopMetricsTopGames = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/top-metrics/top-games',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getTotalAffiliate = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/total-affiliate/summary',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getAffiliateTopSignUp = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/top-affiliate/top-signup',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const getAffiliateTopRevenue = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/top-affiliate/top-revenue',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// Notifications Settings
export const getNotificationSetting = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/notification-setting',
    });
    return res;
  } catch (error) {
    console.error(error.message);
  }
};

export const updateNotificationSetting = async settings => {
  try {
    const res = await Api.request({
      method: 'put',
      data: {
        settings: settings.map(setting => ({
          type: setting.type,
          enabled: setting.enabled.toString(),
        })),
      },
      endpoint: '/notification-setting',
    });
    return res;
  } catch (error) {
    console.error(error.message);
  }
};

// Landing page
export const getTotalUniquePlayers = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/home/total-unique-players',
    });
    return res;
  } catch (error) {
    console.error(error.message);
  }
};

export const getTotalArcadeRevenue = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/home/total-arcade-revenue',
    });
    return res;
  } catch (error) {
    console.error(error.message);
  }
};

export const getTopEarners = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/home/top-earners',
    });
    return res;
  } catch (error) {
    console.error(error.message);
  }
};

// Payment checkout
export const getPaymentCheckoutSessionLink = async (
  paymentMethod: PaymentMethodEnum,
  amount: number,
) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: `payment?paymentMethod=${paymentMethod}&amount=${amount}`,
    });
    return res;
  } catch (error) {
    console.error(error.message);
  }
};

export const getGameLevelDetail = async gameId => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: `/leaderboard/game-level-detail/${gameId}`,
    });
    return res;
  } catch (error) {
    console.error(error.message);
  }
};

/**
 * Retrieves the recommended game based on the provided game ID.
 *
 * @param {string} gameId - The ID of the game.
 * @return {Promise<any>} A promise that resolves with the recommended game.
 */
export const getRecommendedGameList = async (gameId: string) => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: {},
      },
      endpoint: `/game/recommended-game/${gameId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// Reorder game
export const changeOrderGame = async (gameId: string, data) => {
  try {
    const res = await Api.request({
      method: 'put',
      data,
      endpoint: `/game/change-order/${gameId}`,
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

export const downloadGamerData = async (
  downloadGamerData: IDownloadGamerData,
) => {
  try {
    const response = await Api.download({
      method: 'post',
      data: downloadGamerData,
      endpoint: `/game-data/download`,
    });
    if (response?.statusCode === false) return response;
    const contentType = response.headers
      ? response.headers['content-type']
      : 'text/csv; charset=utf-8';
    const contentDisposition = response.headers['content-disposition'];
    const blobData = new Blob([response.data], { type: contentType });
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement('a');
    link.href = url;
    const filenameMatch = contentDisposition?.match(/filename="?([^"]+)"?/);
    const suggestedFilename = filenameMatch
      ? filenameMatch[1]
      : 'downloaded-file.csv';

    link.download = suggestedFilename;
    document.body.appendChild(link);
    link.click();
    link.remove();
    return response;
  } catch (e) {
    console.error('Error downloading file:', e.message);
  }
};

export const getRevenueByGame = async data => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {
        params: data,
      },
      endpoint: '/admin-statistic/revenue',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};

// staking page
export const getStakeInfo = async () => {
  try {
    const res = await Api.request({
      method: 'get',
      data: {},
      endpoint: '/staking',
    });
    return res;
  } catch (e) {
    console.log(e.message);
  }
};
