import { RegisterConnectText, Wrapper } from 'components/Register/styled';
import styled from 'styled-components';

export const LoginWrapper = styled(Wrapper)`
  min-width: 28rem;
  @media (max-width: 28rem) {
    min-width: 100%;
  }
`;

export const LoginLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  margin-top: 1.5rem;
`;

export const ForgotPasswordLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 1.5rem;
`;

export const ForgotPasswordStepLink = styled.div<{ disabled?: boolean }>`
  color: #000;
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-underline-offset: 0.25rem;
  text-align: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  :hover,
  :focus {
    color: #000;
  }
`;

export const LoginConnectText = styled(RegisterConnectText)`
  margin: 2.5rem 0;
`;
