import { LinkOutlined, StockOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ReactComponent as LogoAdminPanel } from 'assets/vectors/logo-navbar-admin-panel.svg';
import { ReactComponent as LogoCashier } from 'assets/vectors/logo-navbar-cashier.svg';
import { ReactComponent as LogoGameManagement } from 'assets/vectors/logo-navbar-game-management.svg';
import { ReactComponent as LogoGames } from 'assets/vectors/logo-navbar-games.svg';
import { ReactComponent as LogoHelp } from 'assets/vectors/logo-navbar-help.svg';
import { ReactComponent as LogoLeaderboard } from 'assets/vectors/logo-navbar-leaderboard.svg';
import { ReactComponent as LogoStaking } from 'assets/vectors/logo-navbar-staking.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface DefaultTheme {
  collapsed: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div<{ theme: DefaultTheme }>`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  display: ${props => props.theme.collapsed && 'none'};
  @media (max-width: 1440px) {
    display: none;
  }
`;

export const NavbarWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  position: fixed;
  /* width: 292px; */
  width: ${props => (props.theme.collapsed ? '118px' : '292px')};
  height: 100vh;
  background-color: #ffffff;
  transition: all 0.3s;
  @media (max-width: 1440px) {
    width: 118px;
  }
  z-index: 999;

  .navbar-collapse-icon {
    display: none;
  }

  :hover,
  :focus {
    .navbar-collapse-icon {
      display: block;
    }
  }
`;

export const LogoWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  padding: ${props =>
    props.theme.collapsed ? '24px 19px 0 27px' : '16px 24px 0'};
  @media (max-width: 1440px) {
    padding: 24px 19px 0 27px;
  }
`;

export const Logo = styled.img<{ theme: DefaultTheme }>`
  width: ${props => (props.theme.collapsed ? '72px' : '83.195px')};
  height: ${props => (props.theme.collapsed ? '27.694px' : '32px')};
  cursor: pointer;
  @media (max-width: 1440px) {
    width: 72px;
    height: 27.694px;
  }
`;

export const Line = styled.span<{ theme: DefaultTheme }>`
  width: ${props => (props.theme.collapsed ? '70px' : '188px')};
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  margin-top: ${props => (props.theme.collapsed ? '12.31px' : '16px')};
  @media (max-width: 1440px) {
    width: 70px;
    margin-top: 12.31px;
  }
`;

export const MainWrapper = styled(Wrapper)`
  flex: 1;
`;

export const MenuWrapper = styled.div<{ theme: DefaultTheme }>`
  flex: 1;
  margin-top: ${props => (props.theme.collapsed ? '32px' : '50px')};
  gap: ${props => props.theme.collapsed && '8px'};
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 168px;
  @media (max-width: 1440px) {
    margin-top: 32px;
    gap: 8px;
  }
`;

export const MenuLogoGames = styled(LogoGames)`
  width: ${props => (props.theme.collapsed ? '28px' : '24px')};
  height: ${props => (props.theme.collapsed ? '28px' : '24px')};
  @media (max-width: 1440px) {
    width: 28px;
    height: 28px;
  }
`;

export const MenuLogoStaking = styled(LogoStaking)`
  width: ${props => (props.theme.collapsed ? '28px' : '24px')};
  height: ${props => (props.theme.collapsed ? '28px' : '24px')};
  path {
    fill: #000;
  }
  @media (max-width: 1440px) {
    width: 28px;
    height: 28px;
  }
`;

export const MenuLogoLeaderboard = styled(LogoLeaderboard)`
  width: ${props => (props.theme.collapsed ? '28px' : '24px')};
  height: ${props => (props.theme.collapsed ? '28px' : '24px')};
  @media (max-width: 1440px) {
    width: 28px;
    height: 28px;
  }
`;
export const MenuLogoCashier = styled(LogoCashier)`
  width: ${props => (props.theme.collapsed ? '28px' : '24px')};
  height: ${props => (props.theme.collapsed ? '28px' : '24px')};
  @media (max-width: 1440px) {
    width: 28px;
    height: 28px;
  }
`;
export const MenuLogoGameManagement = styled(LogoGameManagement)`
  width: ${props => (props.theme.collapsed ? '28px' : '24px')};
  height: ${props => (props.theme.collapsed ? '28px' : '24px')};
  @media (max-width: 1440px) {
    width: 28px;
    height: 28px;
  }
`;
export const MenuLogoAdminPanel = styled(LogoAdminPanel)`
  width: ${props => (props.theme.collapsed ? '28px' : '24px')};
  height: ${props => (props.theme.collapsed ? '28px' : '24px')};
  @media (max-width: 1440px) {
    width: 28px;
    height: 28px;
  }
`;

export const MenuLogoAffiliate = styled(LinkOutlined)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '24px')};
  color: #000;
  @media (max-width: 1440px) {
    font-size: 24px;
  }
`;

export const MenuLogoAdminStats = styled(StockOutlined)`
  font-size: ${props => (props.theme.collapsed ? '28px' : '24px')};
  color: #000;
  @media (max-width: 1440px) {
    font-size: 24px;
  }
`;

export const MenuText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const MenuItemWrapper = styled(Link)`
  display: flex;
  width: 100%;
  padding: ${props => (props.theme.collapsed ? '8px' : '16px 24px')};
  align-items: center;
  align-self: ${props => props.theme.collapsed && 'stretch'};
  justify-content: ${props => props.theme.collapsed && 'center'};
  gap: 8px;
  cursor: pointer;
  @media (max-width: 1440px) {
    padding: 8px;
    align-self: stretch;
    justify-content: center;
  }

  &:hover,
  &:focus,
  &.navbar-active {
    background: rgba(10, 56, 133, 0.08);

    .logo-navbar {
      fill: #0a3885;
    }

    .logo-navbar-stroke {
      stroke: #0a3885;
    }

    ${MenuText}, ${MenuLogoAffiliate}, ${MenuLogoAdminStats} {
      color: #0a3885;
    }
  }
`;

export const ProfileWrapper = styled(Wrapper)<{ theme: DefaultTheme }>`
  width: 100%;
  padding: 16px 24px;
  align-items: ${props => (props.theme.collapsed ? 'center' : 'flex-start')};
  flex-direction: ${props => props.theme.collapsed && 'column'};
  justify-content: ${props => props.theme.collapsed && 'center'};
  gap: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  @media (max-width: 1440px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ProfileMain = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const ProfileAvatarWrapper = styled.div`
  display: flex;
  padding: 6.545px;
  align-items: flex-start;
  gap: 10.909px;
  border-radius: 109.091px;
  border: 1.091px solid rgba(0, 0, 0, 0.16);
`;

export const ProfileAvatar = styled.img`
  width: 34.91px;
  height: 34.91px;
  border-radius: 50%;
`;

export const ProfileText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
`;

export const SupportText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const SupportWrapper = styled(Link)<{ theme: DefaultTheme }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: ${props => (props.theme.collapsed ? '12px 45px 16px' : '16px 24px')};
  justify-content: ${props => props.theme.collapsed && 'center'};
  @media (max-width: 1440px) {
    justify-content: center;
    padding: 12px 45px 16px;
  }

  cursor: pointer;
  user-select: none;
  &:hover,
  &:focus,
  &.navbar-active {
    background: rgba(10, 56, 133, 0.08);

    .logo-navbar {
      fill: #0a3885;
    }

    .logo-navbar-stroke {
      stroke: #0a3885;
    }

    ${SupportText} {
      color: #0a3885;
    }
  }
`;

export const SupportLogo = styled(LogoHelp)<{ theme: DefaultTheme }>`
  width: ${props => (props.theme.collapsed ? '28px' : '24px')};
  height: ${props => (props.theme.collapsed ? '28px' : '24px')};
  @media (max-width: 1440px) {
    width: 28px;
    height: 28px;
  }
`;

export const DropdownWrapper = styled(Menu)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 200px;
`;

export const DropdownItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  padding: 10px;
  cursor: pointer;
  user-select: none;

  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;

  &:last-child {
    border-top: 1px solid #ccc;
  }

  &:hover {
    background: rgba(10, 56, 133, 0.08);
  }
`;

export const CollapseButton = styled.div`
  position: absolute;
  right: -6px;
  top: 80px;

  & > .navbar-collapse-icon {
    scale: 1.6;
    color: #000;
  }

  @media (max-width: 1440px) {
    display: none;
  }
`;
