import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import bigaLogo from 'assets/vectors/logo-biga-arcade.svg';
import { LoginForm } from 'components/Login/LoginForm';
import { PitRouter } from 'constants/routers';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import {
  LoadingPage,
  Logo,
  RegisterWrapper,
  StepWrapper,
} from 'pages/Register/styled';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { playerInfo, loading } = useGetPlayer(null);
  useEffect(() => {
    if (playerInfo) {
      navigate(PitRouter.GAME_LIST);
    }
  }, [playerInfo, navigate]);
  return (
    <RegisterWrapper>
      <Logo
        src={bigaLogo}
        alt="logo"
        onClick={() => {
          navigate(PitRouter.HOME);
        }}
      />
      {loading && (
        <LoadingPage>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </LoadingPage>
      )}
      <StepWrapper>
        <LoginForm />
      </StepWrapper>
    </RegisterWrapper>
  );
};
