import Header from 'components/Layout/Header';
import { Navbar } from 'components/Layout/Navbar';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { ThemeProvider } from 'styled-components';
import { Desktop } from 'styles/Common/HeaderDesktop.styled';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { MainLayout, MobileMenu } from './styled';
import { useEffect, useState } from 'react';
import useWindowSize from 'hooks/useWindowSize';

interface LayoutProps {
  theme?: string;
}

export default function Layout({ theme }: LayoutProps) {
  const collapsed = useAppSelector(state => state.navbar.collapsed);
  const [showDesktopMenu, setShowDesktopMenu] = useState<boolean>(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= 550) setShowDesktopMenu(false);
    else setShowDesktopMenu(true);
  }, [width, setShowDesktopMenu]);

  const handleNavbar = () => {
    if (width <= 550) setShowDesktopMenu(!showDesktopMenu);
    else return;
  };

  return (
    <>
      <Desktop style={{ backgroundColor: theme }}>
        <ThemeProvider
          theme={{
            collapsed,
          }}
        >
          {showDesktopMenu && <Navbar theme={theme} onClick={handleNavbar} />}
          <MobileMenu
            className={showDesktopMenu ? 'with-desktop' : ''}
            onClick={() => setShowDesktopMenu(!showDesktopMenu)}
          >
            <MenuIcon />
          </MobileMenu>
          {/* <MainLayout>
            <Header theme={theme} />
            <Outlet />
          </MainLayout> */}
          <Outlet />
        </ThemeProvider>
      </Desktop>
    </>
  );
}
