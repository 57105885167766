import { Form, Input } from 'antd';
import styled from 'styled-components';

export const RegisterWrapper = styled.div<{ success?: string }>`
  background: var(--biga-blue);
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* @media (max-height: 764px) {
    height: ${props => (props.success === 'true' ? '100vh' : '100%')};
  }
  @media (max-height: 560px) {
    height: ${props => (props.success === 'true' ? '100%' : '100vh')};
  } */
`;

export const Logo = styled.img`
  margin-top: 2.5rem;
  max-width: 150px;
  margin-bottom: 1rem;

  cursor: pointer;
`;

export const TitleStyled = styled.p`
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
`;

export const FormTitleStyled = styled.p`
  color: #000;
  font-size: 25px;
  font-weight: 700;
  margin-top: 15px;
  text-align: center;
`;

export const FooterTextStyled = styled.p`
  font-size: 18.5px;
  font-weight: 450;
  text-align: center;
  margin-top: 20px;
`;

export const LabelStyled = styled.div`
  color: #b6b6b6;
  display: flex;
  font-size: 20px;
  font-weight: 450;
  justify-content: space-between;
  margin: 80px 0 40px;
`;

export const FormWrapper = styled.div`
  margin-top: 42px;
  padding: 35px;
  border-radius: 15px;
  border: 1px solid #b9b9b9;
  min-width: 400px;
  background-color: #fff;

  @media (min-width: 768px) {
    width: 525px;
    min-height: 400px;
  }
  @media (max-width: 450px) {
    min-width: 300px;
  }
  .ant-input-affix-wrapper {
    border: #bcdfce 1px solid;
    height: 38px;
    border-radius: 0;
    :focus,
    :active,
    :focus-visible {
      border-color: #0b3885 !important;
      box-shadow: 0 0 0 2px rgb(35 200 121 / 43%) !important;
    }
    :hover {
      border-color: #0b3885 !important;
    }
  }
  .ant-form-item-explain-error {
    font-size: 13px;
  }
`;

export const CustomFormItem = styled(Form.Item)`
  border: none;
`;

export const InputStyle = styled(Input)`
  border: 1px solid #a7a7a7;
  border-radius: 3.5px;
  font-size: 20px;
  font-weight: 450;
  height: 60px;
  :focus,
  :active,
  :focus-visible {
    border-color: #a7a7a7 !important;
  }
  :hover {
    border-color: #a7a7a7 !important;
  }
  ::placeholder {
    color: #525458;
  }
`;

export const PasswordInputStyle = styled(Input.Password)`
  border: 1px solid #a7a7a7 !important;
  border-radius: 3.5px !important;
  font-size: 20px;
  height: 60px !important;
  :focus,
  :active,
  :focus-visible {
    border-color: #a7a7a7 !important;
    box-shadow: 0 0 0 2px rgb(35 200 121 / 43%) !important;
  }
  :hover {
    border-color: #a7a7a7 !important;
  }
  input::placeholder {
    color: #525458;
    font-weight: 450;
  }
`;

export const FormButtonWrapper = styled(Form.Item)`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 2rem 0 0;
  button {
    background: #0b3885;
    border-radius: 3.5px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    height: 40px;
    width: 200px;
    :hover,
    :focus {
      background: #0b3885;
      color: #fff;
    }
  }
`;

export const TextError = styled.p`
  font-size: 14px;
  color: red;
  margin-bottom: 0;
`;

export const StepWrapper = styled.div`
  top: calc(56px + 3rem);
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: sticky;
`;
export const LoadingPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
