import {
  LeftCircleTwoTone,
  PoweroffOutlined,
  RightCircleTwoTone,
  SolutionOutlined,
} from '@ant-design/icons';
import { Dropdown } from 'antd';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import bigalogo from 'assets/vectors/logo-navbar-biga.svg';
import { ThemedModal } from 'components/Modals/styled';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
import { useLogOut } from 'hooks/useLogOut';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCollapsed } from 'redux/slices/navbar';
import { useAppSelector } from 'redux/store';

import {
  CollapseButton,
  DropdownItemWrapper,
  DropdownWrapper,
  Line,
  Logo,
  LogoWrapper,
  MainWrapper,
  MenuItemWrapper,
  MenuLogoAdminPanel,
  MenuLogoAdminStats,
  MenuLogoAffiliate,
  MenuLogoCashier,
  MenuLogoGameManagement,
  MenuLogoGames,
  MenuLogoLeaderboard,
  MenuLogoStaking,
  MenuText,
  MenuWrapper,
  NavbarWrapper,
  ProfileAvatar,
  ProfileAvatarWrapper,
  ProfileMain,
  ProfileText,
  ProfileWrapper,
  SupportLogo,
  SupportText,
  SupportWrapper,
} from './styled';

interface INavbarProps {
  theme?: string;
  onClick?: () => void;
}

export const Navbar = ({ theme, onClick }: INavbarProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const logOut = useLogOut();
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const collapsed = useAppSelector(state => state.navbar.collapsed);

  const isEnableCashier = useEnableCashier();

  const handleOk = () => {
    logOut();
    navigate(PitRouter.GAME_LIST);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleLogOutClick = () => {
    setVisible(true);
  };

  const handleProfileClick = () => {
    navigate(PitRouter.PROFILE);
  };

  const widgetMenu = (
    <DropdownWrapper>
      <DropdownItemWrapper key="profile" onClick={handleProfileClick}>
        <SolutionOutlined />
        Profile
      </DropdownItemWrapper>
      <DropdownItemWrapper key="logout" onClick={handleLogOutClick}>
        <PoweroffOutlined />
        Logout
      </DropdownItemWrapper>
    </DropdownWrapper>
  );

  const { playerInfo, refreshAvatar } = useAppSelector(state => state.player);
  const avatarURL = useMemo(() => {
    return playerInfo?.avatarURL
      ? `${playerInfo?.avatarURL}?timestamp=${Date.now()}`
      : '';
  }, [playerInfo?.avatarURL, refreshAvatar]);

  return (
    <NavbarWrapper onClick={onClick}>
      <LogoWrapper>
        <Logo
          src={bigalogo}
          alt="logo"
          onClick={() => navigate(PitRouter.HOME)}
        />
        <Line />
      </LogoWrapper>

      <CollapseButton>
        {collapsed ? (
          <RightCircleTwoTone
            twoToneColor="#0a3885"
            className="navbar-collapse-icon"
            onClick={() => dispatch(setCollapsed(!collapsed))}
          />
        ) : (
          <LeftCircleTwoTone
            twoToneColor="#0a3885"
            className="navbar-collapse-icon"
            onClick={() => dispatch(setCollapsed(!collapsed))}
          />
        )}
      </CollapseButton>

      <MainWrapper>
        <MenuWrapper>
          <MenuItemWrapper
            to={PitRouter.GAME_LIST}
            className={pathname === PitRouter.GAME_LIST ? 'navbar-active' : ''}
          >
            <MenuLogoGames />
            <MenuText>Games</MenuText>
          </MenuItemWrapper>

          {playerInfo &&
            playerInfo.roles &&
            !playerInfo.roles.includes(PlayerRoles.Tester) && (
              <MenuItemWrapper
                to={PitRouter.LEADER_BOARD}
                className={
                  pathname === PitRouter.LEADER_BOARD ? 'navbar-active' : ''
                }
              >
                <MenuLogoLeaderboard />
                <MenuText>Leaderboard</MenuText>
              </MenuItemWrapper>
            )}

          {isEnableCashier &&
            playerInfo?.status === PlayerStatus.UploadedProfile &&
            !playerInfo?.roles.includes(PlayerRoles.Tester) && (
              <MenuItemWrapper
                to={PitRouter.CASHIER}
                className={
                  pathname === PitRouter.CASHIER ? 'navbar-active' : ''
                }
              >
                <MenuLogoCashier />
                <MenuText>Cashier</MenuText>
              </MenuItemWrapper>
            )}

          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin) ||
              playerInfo.roles.includes(PlayerRoles.Provider)) && (
              <MenuItemWrapper
                to={PitRouter.MANAGEMENT}
                className={
                  pathname === PitRouter.MANAGEMENT ? 'navbar-active' : ''
                }
              >
                <MenuLogoGameManagement />
                <MenuText>Management</MenuText>
              </MenuItemWrapper>
            )}

          {playerInfo?.roles &&
            playerInfo.roles.includes(PlayerRoles.Influencer) && (
              <MenuItemWrapper
                to={PitRouter.AFFILIATE}
                className={
                  pathname === PitRouter.AFFILIATE ? 'navbar-active' : ''
                }
              >
                <MenuLogoAffiliate />
                <MenuText>Affiliate</MenuText>
              </MenuItemWrapper>
            )}

          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin)) && (
              <MenuItemWrapper
                to={PitRouter.ADMIN_STATS}
                className={
                  pathname === PitRouter.ADMIN_STATS ? 'navbar-active' : ''
                }
              >
                <MenuLogoAdminStats />
                <MenuText>Admin stats</MenuText>
              </MenuItemWrapper>
            )}
          {playerInfo?.roles &&
            (playerInfo.roles.includes(PlayerRoles.SuperAdmin) ||
              playerInfo.roles.includes(PlayerRoles.Admin)) && (
              <MenuItemWrapper
                to={PitRouter.ADMIN_PANEL}
                className={
                  pathname === PitRouter.ADMIN_PANEL ? 'navbar-active' : ''
                }
              >
                <MenuLogoAdminPanel />
                <MenuText>Admin panel</MenuText>
              </MenuItemWrapper>
            )}
          <MenuItemWrapper
            to={PitRouter.STAKING}
            className={pathname === PitRouter.STAKING ? 'navbar-active' : ''}
          >
            <MenuLogoStaking />
            <MenuText>Stake</MenuText>
          </MenuItemWrapper>
        </MenuWrapper>

        {playerInfo && playerInfo.status === PlayerStatus.UploadedProfile && (
          <ProfileWrapper>
            <Dropdown
              overlay={widgetMenu}
              trigger={['click']}
              placement="topRight"
              overlayStyle={{ position: 'fixed' }}
            >
              <ProfileMain>
                <ProfileAvatarWrapper>
                  <ProfileAvatar
                    src={avatarURL ? avatarURL : defaultProfileImg}
                    alt="avatar profile"
                  />
                </ProfileAvatarWrapper>
                <ProfileText>{playerInfo?.username}</ProfileText>
                <ThemedModal
                  visible={visible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText="Logout"
                  cancelText="No"
                  width={400}
                  style={{ marginTop: '50px' }}
                >
                  <p>Do you want to logout?</p>
                </ThemedModal>
              </ProfileMain>
            </Dropdown>
          </ProfileWrapper>
        )}
      </MainWrapper>

      {!playerInfo?.roles.includes(PlayerRoles.Tester) && (
        <SupportWrapper
          to={PitRouter.SUPPORT}
          className={pathname === PitRouter.SUPPORT ? 'navbar-active' : ''}
        >
          <SupportLogo />
          <SupportText>Support</SupportText>
        </SupportWrapper>
      )}
    </NavbarWrapper>
  );
};
