import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { WithdrawModal } from 'components/Modals/Withdraw';
import { CHAIN_NETWORK } from 'constants/chains';
import { EventType } from 'constants/enum/EventType';
import { NULL_ADDRESS } from 'constants/value';
import { BigNumber } from 'ethers';
import { formatEther, formatUnits, parseUnits } from 'ethers/lib/utils';
import { useGetSupportedToken } from 'hooks/cashier/useGetSupportedToken';
import { useStoshiCallback } from 'hooks/stoshi/useStoshiCallback';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import { refreshNewToken } from 'redux/slices/refreshToken';
import { useAppDispatch } from 'redux/store';
import { generateSignatureToWithdraw } from 'services/api';
import { postContractAction } from 'services/api/contract_action';
import { LocalhostStorage } from 'utils/sessionStorage';

import {
  CashierRightContentThirdBlock,
  SecondBlock,
  SecondBlockContent,
  SecondBlockItem,
  SecondBlockItemTitle,
  SecondBlockItemValue,
  SecondBlockItemWrapper,
  SecondBlockTotalTitle,
  SecondBlockTotalValue,
  SecondBlockTotalWrapper,
  ThirdBlockButtonBack,
  ThirdBlockButtonBackWrapper,
  ThirdBlockButtonConfirmWrapper,
  ThirdBlockButtonWrapper,
  ThirdBlockTitle,
  ThirdBlockUnit,
  ThirdBlockValue,
} from './styled';
import { ContractName } from 'constants/enum/ContractName';

interface IProps {
  onBack: () => void;
  value: number;
  confirmData: any;
  convertData: any;
  setDisabled: (value: boolean) => void;
}

export const ConfirmWithdraw = ({
  onBack,
  value,
  confirmData,
  convertData,
  setDisabled,
}: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gasPriceValue, setGasPriceValue] = useState<BigNumber>();
  const [gasLimitValue, setGasLimitValue] = useState<BigNumber>();
  const { getGasLimitForWithdraw, withdraw } = useStoshiCallback();
  const { library } = useWeb3React<Web3Provider>();

  const CHAINID = LocalhostStorage.get('chainId');

  const networkInfo = CHAIN_NETWORK[+CHAINID];

  const DEFAULT_CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

  const toggleWithdrawModal = useToggleModal(ApplicationModal.WITHDRAW);
  const showWithdrawModal = () => {
    toggleWithdrawModal();
  };

  const tokenIn = useMemo(() => {
    return +DEFAULT_CHAIN_ID === CHAINID
      ? process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS
      : NULL_ADDRESS;
  }, [CHAINID]);

  useEffect(() => {
    if (library) {
      const getGasPrice = async () => {
        try {
          const gasPrice = await library.getGasPrice();
          setGasPriceValue(gasPrice);
        } catch (error) {
          console.error('Error getting gas price:', error);
        }
      };
      getGasPrice();

      const getGasLimit = async () => {
        try {
          const res = await getGasLimitForWithdraw(
            confirmData?.address,
            tokenIn,
            convertData,
            0,
            '0x',
          );
          setGasLimitValue(res);
        } catch (error) {
          console.error('Error getting gas limit:', error);
        }
      };
      getGasLimit();
    }
  }, [confirmData?.address, convertData, library]);

  const networkFee = useMemo(() => {
    if (gasPriceValue && gasLimitValue) {
      return formatEther(gasPriceValue.mul(gasLimitValue.mul(2)));
    }
    return formatEther(0);
  }, [gasPriceValue, gasLimitValue]);

  const handleBackClick = () => {
    onBack();
  };

  const convertValue = Number(
    formatUnits(value ? convertData : '0', confirmData?.decimals || 18),
  );

  const { supportToken } = useGetSupportedToken(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
  );

  const dispatch = useAppDispatch();

  const handleWithdraw = async () => {
    try {
      setLoading(true);
      setDisabled(true);

      const CHAIN_ID = LocalhostStorage.get('chainId');
      const sigData = await generateSignatureToWithdraw({
        chainId: CHAIN_ID,
        tokenAddress: tokenIn,
        tokenAddressOut: confirmData?.address,
        amount: parseUnits(
          value.toString() || '0',
          supportToken?.decimals || 18,
        ).toString(),
      });
      if (sigData?.success) {
        const res = await withdraw(
          tokenIn,
          confirmData?.address,
          convertData,
          sigData?.payload?.data?.nonce,
          sigData?.payload?.data?.signature,
        );
        if (res) {
          toast.success('Withdraw success!!!');
          await postContractAction({
            eventName: EventType.Withdrawn,
            transactionHash: res.transactionHash,
            contractName: ContractName.BIGA_CONTRACT,
            chainId: CHAINID,
          });
          dispatch(refreshNewToken());
          handleBackClick();
        }
      }
    } catch (e) {
      toast.error(e.reason);
      console.log(e);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  return (
    <>
      <CashierRightContentThirdBlock>
        <ThirdBlockTitle>Withdrawal Details</ThirdBlockTitle>
        <ThirdBlockValue>{value} BIGA</ThirdBlockValue>
        <ThirdBlockUnit>
          ${convertValue + ' ' + confirmData.symbol}
        </ThirdBlockUnit>
      </CashierRightContentThirdBlock>

      <SecondBlock>
        <SecondBlockContent>
          <SecondBlockItemWrapper>
            <SecondBlockItem>
              <SecondBlockItemTitle>{value} BIGA</SecondBlockItemTitle>
              <SecondBlockItemValue>
                ${confirmData && convertValue + ' ' + confirmData?.symbol}
              </SecondBlockItemValue>
            </SecondBlockItem>
            <SecondBlockItem>
              <SecondBlockItemTitle>
                Estimated network fees
              </SecondBlockItemTitle>
              <SecondBlockItemValue>
                0.0005 {networkInfo?.symbol}
              </SecondBlockItemValue>
            </SecondBlockItem>
          </SecondBlockItemWrapper>
          <SecondBlockTotalWrapper>
            <SecondBlockTotalTitle>Total</SecondBlockTotalTitle>
            <SecondBlockTotalValue>
              <div>
                ${confirmData && convertValue + ' ' + confirmData?.symbol}
              </div>
              <div>0.0005 {networkInfo?.symbol}</div>
            </SecondBlockTotalValue>
          </SecondBlockTotalWrapper>
        </SecondBlockContent>

        <ThirdBlockButtonWrapper>
          <ThirdBlockButtonBackWrapper
            onClick={handleBackClick}
            disabled={loading}
          >
            <ThirdBlockButtonBack>Back</ThirdBlockButtonBack>
          </ThirdBlockButtonBackWrapper>
          <ThirdBlockButtonConfirmWrapper onClick={showWithdrawModal}>
            Confirm withdraw
          </ThirdBlockButtonConfirmWrapper>
        </ThirdBlockButtonWrapper>
        <WithdrawModal
          value={value}
          handleWithdraw={handleWithdraw}
          loading={loading}
        />
      </SecondBlock>
    </>
  );
};
